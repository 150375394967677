// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { styled } from "@mui/material/styles";

import { Box, CalendarIcon, ERadius, ESpacing, constants, useFormState } from "@hkexpressairwayslimited/ui/src";
import { DatePickerProps, LocalizationProvider, DatePicker as MUIDatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { isValid } from "date-fns";
import { get } from "lodash";
import { FieldValues } from "react-hook-form";

const StyledDatePicker = styled(MUIDatePicker)(({ isError }: { isError: boolean }) => ({
  width: "100%",
  minHeight: "56px",
  "& .MuiInputBase-adornedEnd": {
    borderRadius: ERadius.r200,
  },
  "& .MuiIconButton-sizeMedium": {
    position: "absolute",
    left: ESpacing._2xs,
  },
  "& .MuiInputBase-input": {
    marginLeft: ESpacing._md,
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    minHeight: "56px",
    borderColor: isError ? "#e60065" : "rgba(0, 0, 0, 0.23)",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#59107d",
  },
  "& .MuiInputLabel-shrink": {
    marginLeft: "0 !important",
  },
  "& .MuiInputLabel-sizeMedium": {
    marginLeft: ESpacing._md,
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#e60065 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e60065 !important",
    borderWidth: "1px",
  },
  "& .MuiInputLabel-formControl": {
    color: "#4d4d4d",
    fontFamily: constants.varFontPlutoSans,
  },
  "& .MuiFormLabel-root.MuiFormLabel-root.Mui-focused": {
    color: "#000",
    opacity: "0.87",
  },
  "& .MuiInputLabel-formControl.MuiInputLabel-shrink": {
    color: "#000",
    opacity: "0.87",
    fontSize: "14px",
    fontFamily: constants.varFontPlutoSansLight,
  },
  "& .MuiInputBase-formControl": {
    width: "100%",
  },
  "& .MuiInputBase-formControl.Mui-disabled": {
    backgroundColor: "#e5e5e5",
  },
}));

const DatePicker = (
  props: { onChange?: (value: Date | string) => void; onBlur?: () => void } & DatePickerProps<Date>
) => {
  const { errors } = useFormState<FieldValues>({ name: props.name });
  const fieldError = props.name ? get(errors, props.name) : null;
  return (
    <Box onBlur={props.onBlur}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledDatePicker
          {...(props as any)}
          onChange={(newValue) => {
            if (isValid(newValue)) {
              props.onChange?.(newValue as Date);
            } else {
              props.onChange?.("");
            }
          }}
          slots={{ openPickerIcon: CalendarIcon }}
          format='yyyy/MM/dd'
          isError={fieldError}
        />
      </LocalizationProvider>
    </Box>
  );
};

export { DatePicker };
