import { EMAIL_REGEXP } from "@hkexpressairwayslimited/ui/src";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { LoadingBackdrop } from "../../Molecules/LoadingBackdrop";
import { PaymentFormBookingInformation } from "../../Organisms/PaymentRequestForm/PaymentFormBookingInformation/PaymentFormBookingInformation";
import { PaymentFormRequiredInformation } from "./PaymentFormRequiredInformation/PaymentFormRequiredInformation";
import { PaymentFormSummary } from "./PaymentFormSummary/PaymentFormSummary";
import { PaymentFormTransactionDetails } from "./PaymentFormTransactionDetails/PaymentFormTransactionDetails";
import { PaymentDataTypes } from "./paymentTypes";

export type FieldValues = {
  notificationEmail: string;
  market?: string;
  // sales_port: string;
  app_code: string;
  // brand_code: string;
  pnr: string;
  first_name?: string;
  last_name?: string;
  // sales_reference: string;
  email_address: string;
  sales_Item: {
    product: string;
    description: string;
    currency: string;
    amount: number;
  }[];
  language: string;
  expiry_time: string;
  note?: string;
};

export type inputProductData = {
  amount: number;
  currency: string;
  description?: string;
  label?: string;
  product?: string;
};

export type itemTypeData = {
  amount: number;
  currency: string;
  label: string;
  sub_items: inputProductData[];
};
export interface PaymentFormProps {
  data: PaymentDataTypes;
  postFormValue: (submitData: any, handleClose: () => void) => Promise<void>;
}

const calTotal = (results: inputProductData[] | itemTypeData[]) => {
  let total = 0;
  results.map((result: any) => {
    if (result.amount !== undefined) {
      total += parseFloat(result.amount);
    } else {
      total += 0;
    }
  });
  return total;
};

const schema = yup.object().shape({
  notificationEmail: yup
    .string()
    .email("A valid email format is required.")
    .matches(EMAIL_REGEXP, "A valid email format is required.")
    .required("Valid notification email address is required."),
  market: yup.string().optional(),
  // sales_port: yup
  //   .string()
  //   .required("Sales port is required.")
  //   .matches(/^[a-zA-Z]*$/, "Sales Port must contain only alphabets"),
  app_code: yup.string().required("App Code is required."),
  // brand_code: yup.string().required("Brand is required."),
  pnr: yup.string().required("Sales reference is required."),
  first_name: yup.string().optional(),
  last_name: yup.string().optional(),
  // sales_reference: yup.string().required("Sales reference is required."),
  email_address: yup
    .string()
    .email("A valid email format is required.")
    .matches(EMAIL_REGEXP, "A valid email format is required.")
    .required("Valid email address of client is required."),
  sales_Item: yup
    .array()
    .of(
      yup.object().shape({
        product: yup.string().required("Product name is required."),
        description: yup.string().required("Product description is required."),
        currency: yup.string().required("Currency is required."),
        amount: yup.number().required("Amount is required."),
      })
    )
    .required(),
  language: yup.string().required("Language is required."),
  expiry_time: yup.string().required("Expiry time is required."),
  note: yup.string().optional(),
});

const PaymentForm = (props: PaymentFormProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      notificationEmail: "",
      market: "",
      // sales_port: "",
      app_code: "",
      // brand_code: "",
      // sales_reference: "",
      email_address: "",
      sales_Item: [{ product: "", description: "", currency: "", amount: 0 }],
      language: "",
      expiry_time: "",
      pnr: "",
      first_name: "",
      last_name: "",
      note: "",
    },
  });

  const { setValue } = methods;

  const onSubmit = async (data: FieldValues) => {
    handleOpen();
    console.log("Testing payment link", data);
    const products: { [key: string]: inputProductData[] } = {};
    const types: { [key: string]: itemTypeData[] } = {};

    data.sales_Item.map((result: any) => {
      const withoutDescription = {
        amount: result.amount,
        currency: result.currency,
        label: result.product,
      };
      if (products[result.product] === undefined) {
        products[result.product] = [];

        products[result.product].push(withoutDescription);
      } else if (products[result.product] !== undefined) {
        products[result.product].push(withoutDescription);
      }
    });

    const subItem = Object.keys(products).map((product) => {
      const found = Object?.values(props.data.product).find((item) => item.value === product);
      if (found !== undefined && !types[found.type]) {
        types[found.type] = [];
      }
      const total = calTotal(products[product]);
      return {
        amount: total,
        currency: "HKD",
        label: product,
        sub_items: products[product],
        label_cms_key: "responsive_ope_summary-details_details_list_flights",
      };
    });

    const items = Object.keys(types).map((type, index) => {
      subItem.map((itemGroup) => {
        const found = Object?.values(props.data.product).find((product) => product.value === itemGroup.label);
        if (found !== undefined && found.type === type) {
          types[type].push(itemGroup);
        }
      });

      return {
        item_reference: `sales_item_${index}`,
        label: type,
        label_cms_key: `responsive_ope_summary-CTA1_${type}`,
        currency: "HKD",
        amount: calTotal(types[type]),
        sub_items: types[type],
      };
    });

    const submitData = {
      // notification_email: data.notificationEmail,
      // market: data.market,
      // sales_port: data.sales_port.toUpperCase(),
      // app_code: data.app_code,
      // brand_code: data.brand_code,
      // sales_reference: data.sales_reference,
      // expiry_time: data.expiry_time,
      // sales_summary: {
      //   items: items,
      //   total: {
      //     label: "Grand total (including taxes)",
      //     currency: "HKD",
      //     amount: calTotal(data.sales_Item),
      //   },
      // },
      sales_reference: data.pnr,
      first_name: data.first_name,
      last_name: data.last_name,
      receiver: {
        language: data.language,
        email_address: data.email_address,
      },
      note: data.note,
    };

    props.postFormValue(submitData, handleClose);
  };

  // const onAutoFill = () => {
  //   setValue("notificationEmail", "CPPABC@cathaypacific.com");
  //   setValue("sales_port", "HKG");
  //   setValue("app_code", "GBS");
  //   setValue("brand_code", "CX");
  //   setValue("sales_reference", "123456");
  //   setValue("email_address", "test@gmail.com");
  //   setValue("sales_Item", [
  //     { product: "Asia Miles Gift Miles", description: "Flight to HK", currency: "HKD", amount: 100 },
  //   ]);
  //   setValue("language", "EN");
  //   setValue("expiry_time", "43200");
  // };

  return (
    <FormProvider {...methods}>
      <LoadingBackdrop isOpen={open} />
      <div>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columnSpacing={{ md: 2 }}>
            <Grid item xs={12} md={9}>
              <Grid container direction='column' minWidth={"550px"}>
                <PaymentFormBookingInformation data={props.data} />
                <PaymentFormTransactionDetails data={props.data} />
                <PaymentFormRequiredInformation data={props.data} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} minWidth={{ xs: "550px", md: "190px" }}>
              <PaymentFormSummary data={props.data} summaryType='generate' />
              {/* <Button
                variant={ButtonVariant.Primary}
                type='button'
                style={{ marginTop: "16px", width: "100%" }}
                onClick={() => onAutoFill()}>
                Auto Fill
              </Button> */}
            </Grid>
          </Grid>
        </form>
      </div>
    </FormProvider>
  );
};

export { calTotal, PaymentForm };
