import { Box, PaymentMenu, PaymentTextField, useFieldArray, useFormContext } from "@hkexpressairwayslimited/ui/src";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PaymentDataProps } from "../paymentTypes";
import classes from "./PaymentFormTransactionDetails.module.scss";

const PaymentFormTransactionDetails = ({ data }: PaymentDataProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    rules: { required: true },
    name: "sales_Item",
  });

  type SalesItemProps = {
    index: number;
  };

  const SalesItem = ({ index }: SalesItemProps) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClickClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      console.log("Testing fields", fields);
    }, [fields]);

    return (
      <>
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography sx={{ fontSize: "0.9rem" }}>Sales Item</Typography>
            {fields.length > 1 && (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ClearIcon
                  onClick={handleClickOpen}
                  sx={{
                    color: "#702b91",
                    fontSize: "1rem",
                    marginTop: 0,
                    cursor: "pointer",
                  }}
                />
                <span onClick={handleClickOpen} className={classes.transactionDetails_removeItem}>
                  Remove
                </span>
              </Box>
            )}
            {open && (
              <Dialog
                disableScrollLock
                open={open}
                onClose={handleClickClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>{"Remove Item"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>Do you want to remove this item?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClickClose} sx={{ color: "#702b91" }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      remove(index);
                    }}
                    sx={{ color: "#702b91" }}
                    autoFocus
                  >
                    Remove
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PaymentMenu data={data.product} label='Product' id='product' name={`sales_Item.${index}.product`} />
        </Grid>
        <Grid item xs={12}>
          <PaymentTextField
            id='description'
            label='Description'
            variant='filled'
            name={`sales_Item.${index}.description`}
            customControl={control}
          />
        </Grid>
        <Grid item xs={4}>
          <PaymentMenu data={data.currency} label='Currency' id='currency' name={`sales_Item.${index}.currency`} />
        </Grid>
        <Grid item xs={8}>
          <PaymentTextField
            type='number'
            id='amount'
            label='Amount'
            variant='filled'
            name={`sales_Item.${index}.amount`}
            customControl={control}
          />
        </Grid>
      </>
    );
  };
  return (
    <Stack direction='row' spacing={2} paddingTop={4} paddingBottom={4} borderBottom={1}>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant='subtitle1' color={"#702B91"}>
              Transaction Details
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaymentTextField
                id='email_address'
                label='Email Address'
                variant='filled'
                name='email_address'
                customControl={control}
              />
            </Grid>

            {fields.map((field, index) => (
              <SalesItem key={field.id} index={index} />
            ))}

            <Grid item xs={4} />
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                disabled={fields.length >= 5 ? true : false}
                variant='outlined'
                sx={{
                  color: "#702B91",
                  borderColor: "#702B91",
                  borderRadius: 0,
                }}
                fullWidth
                onClick={() => {
                  if (fields.length < 5) {
                    append({
                      product: "",
                      description: "",
                      currency: "",
                      amount: 0,
                    });
                  }
                }}
              >
                + Add item
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export { PaymentFormTransactionDetails };
