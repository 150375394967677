import { Stack } from "@mui/material";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  ErrorLabel,
  FieldInputGroup,
  Font,
  Form,
  Link,
  Modal,
  ModalHandle,
  Text,
  TextInput,
  useForm,
} from "../..";
import classes from "./Membership.module.scss";

export enum OtpType {
  Mobile = "Mobile",
  Email = "Email",
}

export const maskEmail = (email: string) => {
  // return email.replaceAll(/(?<=.{2})[^@\n](?=[^@\n]*[^@\n]{2}@)/gm, "*"); // cannot use as safari does not support (?<=)
  const [target, domain] = email.split("@");
  if (target.length <= 4) {
    return email;
  }
  const first = target.substring(0, 2);
  const last = target.substring(target.length - 2);
  const mask = target.substring(2, target.length - 2).replace(/./g, "*");
  return `${first}${mask}${last}@${domain}`;
};

export const maskPhone = (phone: string) => {
  return phone.substring(0, 4) + phone.substring(4).split("").fill("*").join("");
};
interface CustomProps {
  id?: string;
  onClickCta?: (confirmationCode: string, marketingChecked?: boolean) => void | Promise<void>;
  onClickResendOtp?: () => void;
  errorMsg?: string | JSX.Element | JSX.Element[];
  i18nContent?: { [key: string]: string | JSX.Element | JSX.Element[] };
  type?: OtpType | null;
  noCrossIcon?: boolean;
}
export const OTP_RESEND_INTERVAL_S = 60;
export const EmailOtpBox = forwardRef<ModalHandle, CustomProps>((props: CustomProps, ref) => {
  enum Fields {
    ConfirmationCode1 = "ConfirmationCode1",
    ConfirmationCode2 = "ConfirmationCode2",
    ConfirmationCode3 = "ConfirmationCode3",
    ConfirmationCode4 = "ConfirmationCode4",
    ConfirmationCode5 = "ConfirmationCode5",
    ConfirmationCode6 = "ConfirmationCode6",
    // Marketing = "Marketing",
  }
  const defaultValues = {
    [Fields.ConfirmationCode1]: "",
    [Fields.ConfirmationCode2]: "",
    [Fields.ConfirmationCode3]: "",
    [Fields.ConfirmationCode4]: "",
    [Fields.ConfirmationCode5]: "",
    [Fields.ConfirmationCode6]: "",
    // [Fields.Marketing]: false,
  };
  type FormValues = typeof defaultValues; // Or define your own schema
  const FormSchemas = {
    [Fields.ConfirmationCode1]: yup.string().required(" "),
    [Fields.ConfirmationCode2]: yup.string().required(" "),
    [Fields.ConfirmationCode3]: yup.string().required(" "),
    [Fields.ConfirmationCode4]: yup.string().required(" "),
    [Fields.ConfirmationCode5]: yup.string().required(" "),
    [Fields.ConfirmationCode6]: yup.string().required(" "),
    // [Fields.Marketing]: yup.boolean().required(),
  };
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape({
      [Fields.ConfirmationCode1]: FormSchemas[Fields.ConfirmationCode1],
      [Fields.ConfirmationCode2]: FormSchemas[Fields.ConfirmationCode2],
      [Fields.ConfirmationCode3]: FormSchemas[Fields.ConfirmationCode3],
      [Fields.ConfirmationCode4]: FormSchemas[Fields.ConfirmationCode4],
      [Fields.ConfirmationCode5]: FormSchemas[Fields.ConfirmationCode5],
      [Fields.ConfirmationCode6]: FormSchemas[Fields.ConfirmationCode6],
      // [Fields.Marketing]: FormSchemas[Fields.Marketing],
    }),
  });
  const [errorMsg, setErrorMsg] = useState<string | JSX.Element | JSX.Element[]>("");
  const errors = useMemo(() => {
    const errorsList = formHook.formState.errors;
    return Object.keys(errorsList).length > 0;
  }, [formHook.formState]);

  const i18nContent = props?.i18nContent || {};
  const type = props?.type || OtpType.Email;
  // const marketingChecked = formHook.watch(Fields.Marketing);
  const confirmationCode1 = formHook.watch(Fields.ConfirmationCode1);
  const confirmationCode2 = formHook.watch(Fields.ConfirmationCode2);
  const confirmationCode3 = formHook.watch(Fields.ConfirmationCode3);
  const confirmationCode4 = formHook.watch(Fields.ConfirmationCode4);
  const confirmationCode5 = formHook.watch(Fields.ConfirmationCode5);
  const confirmationCode6 = formHook.watch(Fields.ConfirmationCode6);

  const [otpTimeLeft, setOtpTimeLeft] = useState<number>(0);
  const close = useCallback(() => {
    props.onClickCta &&
      props.onClickCta(
        `${confirmationCode1}${confirmationCode2}${confirmationCode3}${confirmationCode4}${confirmationCode5}${confirmationCode6}`
      );
  }, [
    confirmationCode1,
    confirmationCode2,
    confirmationCode3,
    confirmationCode4,
    confirmationCode5,
    confirmationCode6,
  ]);

  const handlePaste = async (e: any) => {
    try {
      const text = await navigator.clipboard.readText();
      if (text?.length === 6) {
        const textArr = text.split("");
        formHook.setValue(Fields.ConfirmationCode1, textArr[0], { shouldValidate: true });
        formHook.setValue(Fields.ConfirmationCode2, textArr[1], { shouldValidate: true });
        formHook.setValue(Fields.ConfirmationCode3, textArr[2], { shouldValidate: true });
        formHook.setValue(Fields.ConfirmationCode4, textArr[3], { shouldValidate: true });
        formHook.setValue(Fields.ConfirmationCode5, textArr[4], { shouldValidate: true });
        formHook.setValue(Fields.ConfirmationCode6, textArr[5], { shouldValidate: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (otpTimeLeft === 0) {
      setOtpTimeLeft(0);
    }
    if (!otpTimeLeft) return;
    const intervalId = setInterval(() => {
      setOtpTimeLeft(otpTimeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [otpTimeLeft]);
  const handleClickResendOtp = () => {
    setOtpTimeLeft(OTP_RESEND_INTERVAL_S);
    props.onClickResendOtp && props.onClickResendOtp();
  };
  const setIsOpen = (isOpen: boolean) => {
    if (isOpen) {
      setOtpTimeLeft(OTP_RESEND_INTERVAL_S);
      formHook.setValue(Fields.ConfirmationCode1, "");
      formHook.setValue(Fields.ConfirmationCode2, "");
      formHook.setValue(Fields.ConfirmationCode3, "");
      formHook.setValue(Fields.ConfirmationCode4, "");
      formHook.setValue(Fields.ConfirmationCode5, "");
      formHook.setValue(Fields.ConfirmationCode6, "");
    }
  };
  const onSubmit = () => {
    close();
  };
  const onOtpCharValueChange = useCallback((_info: { name: any }, e: any) => {
    let v = e.target.value?.toUpperCase() || "";
    v = type === OtpType.Email ? v.replace?.(/[^\dA-Z]/g, "") : v.replace?.(/[^\d]/g, "");
    if (v?.length > 1) {
      v = v.substring(0, 1);
    }
    setTimeout(() => {
      formHook.setValue(Fields[_info.name as Fields], v);
      if (v.length > 0) {
        // focus next
        const form = e.target.form;
        const index = [...form].indexOf(e.target);
        form[index + 1].focus();
      }
    });
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      const form = (e.target as HTMLFormElement).form;
      const index = [...form].indexOf(e.target);
      if (index > 0) {
        form[index - 1].focus();
        formHook.setValue(Fields[`ConfirmationCode${index}` as Fields], "");
        formHook.setValue(Fields[`ConfirmationCode${index + 1}` as Fields], "");
      }
    }
  };

  useEffect(() => {
    if (errors) {
      setErrorMsg("");
    }
  }, [errors]);

  useEffect(() => {
    setErrorMsg(props.errorMsg || "");
  }, [props.errorMsg]);

  return (
    <Modal title={i18nContent.title} ref={ref} setIsOpen={setIsOpen} noCrossIcon={props.noCrossIcon}>
      <Form<FormValues> formHook={formHook} onSubmit={onSubmit}>
        <Stack spacing={1} sx={{ padding: `0 1px` }}>
          <Text>{i18nContent.msgText}</Text>
          <Stack spacing={1} direction='row' justifyContent='center'>
            {Array.from({ length: 6 }).map((item, index) => {
              return (
                <Box key={index} sx={{ width: "54px" }}>
                  <FieldInputGroup
                    onValueChange={onOtpCharValueChange}
                    names={[Fields[`ConfirmationCode${index + 1}` as Fields]]}
                    helperText={""}
                    hideErrorMessage
                  >
                    <TextInput fullWidth onPaste={handlePaste} onKeyDown={handleKeyDown} placeholder={""} center />
                  </FieldInputGroup>
                </Box>
              );
            })}
          </Stack>
          {errors && <ErrorLabel className={classes.formStory_error_label}>{i18nContent.invalidCode}</ErrorLabel>}
          {/* <FieldInputGroup
            names={[
              Fields.ConfirmationCode1,
              Fields.ConfirmationCode2,
              Fields.ConfirmationCode3,
              Fields.ConfirmationCode4,
              Fields.ConfirmationCode5,
              Fields.ConfirmationCode6,
            ]}
            helperText={""}
          >
            <TextInput placeholder={""} />
            <TextInput placeholder={""} />
            <TextInput placeholder={""} />
            <TextInput placeholder={""} />
            <TextInput placeholder={""} />
            <TextInput placeholder={""} />
          </FieldInputGroup> */}
          <Font color={"red"} textAlign={"center"}>
            {errorMsg}
          </Font>
          {/* <FieldInput name={Fields.Marketing}>
            <FormControlLabel control={<Checkbox />} label={props.marketingText} />
          </FieldInput> */}
          <Button submit fullWidth disabled={!formHook.formState.isValid}>
            {i18nContent.ctaText}
          </Button>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
            <Stack direction={"row"}>
              <Text>{i18nContent.resendText}</Text>
              <Text> </Text>
              {otpTimeLeft > 0 ? (
                // <Text>{"Send again ({{SECOND}}s)".replace("{{SECOND}}", `${otpTimeLeft}`)}</Text>
                <Link href='javascript:void(0)' onClick={() => {}}>
                  {(i18nContent.resendTextsLink as string)?.replace("{{second}}", `${otpTimeLeft}`)}
                </Link>
              ) : (
                <Link href='javascript:void(0)' onClick={handleClickResendOtp}>
                  {(i18nContent.resendTextLink as string)?.replace("{{second}}", `${otpTimeLeft}`)}
                </Link>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </Modal>
  );
});
EmailOtpBox.displayName = "EmailOtpBox";
