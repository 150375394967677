import {
  ChangeLeftRightIcon,
  DestinationIcon,
  Dropdown,
  ESpacing,
  FerryIcon,
  FieldInputGroup,
  FlightIcon,
  FormFieldProps,
  SelectGroup,
  SelectOption,
  SvgIcon,
  SvgIconSize,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";
import { groupBy } from "lodash";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";

export type TripFromToProps = {
  names: string[];
  flightRouteOptions: any;
  isChangeLeftRight?: boolean;
  formHook?: any;
  i18nContent?: any;
} & Partial<FormFieldProps>;

type optionProps = {
  groupLabel: string;
  groupId: string;
  options: any;
};

const groupOptions = (salesOptions: any, airportCode?: any) => {
  const replaceLabel = [{ name: "BK1", replace: "BKK" }];
  const salesOptionsGroup = groupBy(salesOptions, (salesFromOption) => salesFromOption?.market);
  const endOptions = Object.entries(salesOptionsGroup).map(([key, value]: [string, any]) => {
    const options = value?.map((option: any) => {
      const replace = replaceLabel.find((item) => item.name === option?.airport_code);
      return {
        label: `${airportCode[option?.airport_code]} ${option?.airport_code}` || "",
        value: option?.airport_code || "",
        displayLabel: (
          <span>
            {airportCode[option?.airport_code]}
            <strong style={{ marginRight: ESpacing._3xs, marginLeft: ESpacing._3xs }}>
              {replace ? replace.replace : option?.airport_code}
            </strong>
            {option?.is_pier && (
              <SvgIcon size={SvgIconSize.Desktop}>
                <FerryIcon />
              </SvgIcon>
            )}
          </span>
        ),
      };
    });
    return {
      groupLabel: airportCode[key] || "",
      groupId: key,
      options,
    };
  });
  return endOptions;
};

export const TripFromTo = forwardRef((props: TripFromToProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { isChangeLeftRight, flightRouteOptions, formHook, names, disabled = false, i18nContent = {} } = props;
  const [fromOptions, setFromOptions] = useState<any[]>([]);
  const [toOptions, setToOptions] = useState<any[]>([]);
  const [salesPortObj, setSalesPortObj] = useState<any>({});

  const handleOptionsOrder = (options: optionProps[]) => {
    // set HK to the top
    const hkOptionIndex = options.findIndex((item: any) => item?.groupId === "HK");
    if (hkOptionIndex !== -1) {
      const hkOption = options.splice(hkOptionIndex, 1)[0];
      options.sort((a, b) => a.groupLabel.localeCompare(b.groupLabel));
      options.unshift(hkOption);
    }
    // option sort by alphabet
    options.forEach((option: optionProps) => {
      option.options.sort((a: any, b: any) => a.label.localeCompare(b.label));
    });
    return options;
  };

  useEffect(() => {
    if (!disabled && flightRouteOptions) {
      const salesPortGrouping = flightRouteOptions?.sales_port_grouping || [];
      const flightRouteMappings = flightRouteOptions?.flight_route_mappings || [];
      salesPortGrouping.forEach((salesPort: any) => {
        const ports = salesPort?.ports || [];
        const market = salesPort?.market || "";
        ports.forEach((port: { airport_code: string; is_pier: boolean }) => {
          const airport_code = port.airport_code || "";
          salesPortObj[airport_code] = { ...port, market };
          setSalesPortObj(salesPortObj);
        });
      });
      const salesFromOptions: any = [];
      flightRouteMappings.forEach((flightRouteMapping: any) => {
        salesFromOptions.push(salesPortObj[flightRouteMapping?.origin]);
      });

      const endFromOptions = handleOptionsOrder(groupOptions(salesFromOptions, i18nContent.airportCode));
      setFromOptions(endFromOptions);
      setToOptions(endFromOptions);
    }
  }, [flightRouteOptions]);

  const changeFromTo = () => {
    const v0 = formHook?.getValues(names[0]);
    const v1 = formHook?.getValues(names[1]);
    formHook?.setValue(names[0], v1);
    formHook?.setValue(names[1], v0);
  };

  useEffect(() => {
    if (!disabled) {
      const v0 = formHook?.getValues(names[0]);
      const v1 = formHook?.getValues(names[1]);
      const flightRouteMappings = flightRouteOptions?.flight_route_mappings || [];
      const curFlightRoute = flightRouteMappings.find((flightRouteMapping: any) => flightRouteMapping?.origin === v0);
      const destinations = curFlightRoute?.destination || [];
      const salesToOptions: any = [];
      destinations.forEach((destination: string) => {
        salesToOptions.push(salesPortObj[destination]);
      });

      const endToOptions = groupOptions(salesToOptions, i18nContent.airportCode);
      if (v1) {
        let isHasValue = false;
        for (let i = 0; i < endToOptions.length; i++) {
          const curItem = (endToOptions[i] as SelectGroup)?.options.find((item) => (item as SelectOption).value === v1);
          if (curItem) {
            isHasValue = true;
            break;
          }
        }
        if (!isHasValue) {
          formHook?.setValue(names[1], null);
        }
      }
      if (v0) {
        setToOptions(handleOptionsOrder(endToOptions));
      }
    }
  }, [formHook?.watch(names[0])]);

  useEffect(() => {
    if (!disabled) {
      const v0 = formHook?.getValues(names[0]);
      const v1 = formHook?.getValues(names[1]);
      const flightRouteMappings = flightRouteOptions?.flight_route_mappings || [];
      const curFlightRoute = flightRouteMappings.find((flightRouteMapping: any) => flightRouteMapping?.origin === v0);

      if (toOptions.length === 0 || !v0) {
        const salesToOptions: any = [];
        flightRouteMappings.forEach((flightRouteMapping: any) => {
          salesToOptions.push(salesPortObj[flightRouteMapping?.origin]);
        });
        const endToOptions = handleOptionsOrder(groupOptions(salesToOptions, i18nContent.airportCode));
        setToOptions(endToOptions);
      }
      if (curFlightRoute && !curFlightRoute?.destination.some((e: any) => e === v1)) {
        formHook?.setValue(names[1], null);
      }
    }
  }, [formHook?.watch(names[1], toOptions)]);

  const { isDesktop } = useMediaQueries();
  const dividerProps = isChangeLeftRight
    ? {
        dividers: [
          <SvgIcon
            onClick={changeFromTo}
            key={Math.random()}
            sx={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
              transform: !isDesktop ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            <ChangeLeftRightIcon />
          </SvgIcon>,
        ],
      }
    : { divider: true };
  return (
    <FieldInputGroup
      disabled={disabled}
      fullWidth
      helperText={[i18nContent.from, i18nContent.to]}
      names={names}
      vertical={!isDesktop}
      {...dividerProps}
    >
      <Dropdown
        hideArrow
        fullWidth
        trianglePopupTitleLabel={i18nContent.from}
        canSearch
        isGrouping
        options={fromOptions}
        svgIcon={
          <SvgIcon sx={{ width: "24px", height: "24px" }}>
            <FlightIcon />
          </SvgIcon>
        }
        placeholder={i18nContent.from}
      />
      <Dropdown
        hideArrow
        fullWidth
        trianglePopupTitleLabel={i18nContent.to}
        canSearch
        isGrouping
        options={toOptions}
        svgIcon={
          <SvgIcon sx={{ width: "24px", height: "24px" }}>
            <DestinationIcon />
          </SvgIcon>
        }
        placeholder={i18nContent.to}
      />
    </FieldInputGroup>
  );
});
TripFromTo.displayName = "TripFromTo";
