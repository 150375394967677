import { envUiConfig } from "../env";

export function secureMediaURL(url: string) {
  if (!envUiConfig.sitecoreApiHost || !envUiConfig.sitecoreHost || !envUiConfig.sitecoreInstanceId) {
    return url;
  }

  return url
    .replaceAll(`https://edge.sitecorecloud.io/${envUiConfig.sitecoreInstanceId}`, `${envUiConfig.sitecoreHost}/edge/`)
    .replaceAll(
      `${envUiConfig.sitecoreApiHost}/-/media/Project/${envUiConfig.sitecoreSiteName}/${envUiConfig.sitecoreSiteName}/data/media/`,
      `${envUiConfig.sitecoreHost}/source/`
    )
    .replaceAll(`${envUiConfig.sitecoreApiHost}`, `${envUiConfig.sitecoreHost}`);
}

type NestedObject = { [key: string]: string | NestedObject | NestedObject[] } | NestedObject[];

export function secureNestedObject(obj: NestedObject): NestedObject {
  if (Array.isArray(obj)) {
    return obj.map(secureNestedObject);
  } else if (typeof obj === "object" && obj !== null) {
    const result: { [key: string]: any } = {};
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        result[key] = secureNestedObject(obj[key] as NestedObject);
      } else if (typeof obj[key] === "string") {
        result[key] = secureMediaURL(obj[key] as string);
      } else {
        result[key] = obj[key]; // Include other types of values
      }
    }
    return result;
  }
  return obj;
}
