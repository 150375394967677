import MuiPagination from "@mui/material/Pagination";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { useHiddenGroup, useMediaQueries } from "@hkexpressairwayslimited/ui/src";
import classes from "./Pagination.module.scss";

export type PaginationProps = {
  children?: ReactNode;
  defaultPage?: number;
  itemsPerPage?: number;
  siblingCount?: number;
  count?: number;
  onChange?: (page: number, props: PaginationProps) => void;
};

const paginationTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export const Pagination = ({
  children,
  siblingCount = 4,
  defaultPage = 1,
  itemsPerPage = 10,
  count,
  onChange,
}: PaginationProps) => {
  const props = { children, siblingCount, defaultPage, itemsPerPage, count, onChange };
  const { isDesktop, isMobile, isTablet } = useMediaQueries();
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const { components: childrenList, count: pageCount } = useHiddenGroup(
    children,
    (currentPage - 1) * itemsPerPage,
    itemsPerPage
  );
  const actualCount = useMemo(() => count || pageCount, [count, pageCount]);
  const totalCount = useMemo(() => Math.ceil(actualCount / itemsPerPage), [itemsPerPage, count]);
  console.log("childrenList", children, childrenList, actualCount);

  const handleChange = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    onChange?.(currentPage, props);
  }, [currentPage]);

  return (
    <ThemeProvider theme={paginationTheme}>
      <div className={classes.pagination}>
        <div>{childrenList}</div>
        <MuiPagination
          siblingCount={isDesktop ? siblingCount : Math.min(siblingCount, 2)}
          shape='rounded'
          variant='outlined'
          hideNextButton={isTablet || isMobile}
          hidePrevButton={isTablet || isMobile}
          showFirstButton
          showLastButton
          count={totalCount}
          onChange={handleChange}
        />
      </div>
    </ThemeProvider>
  );
};
