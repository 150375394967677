import {
  Box,
  CheckIcon,
  Container,
  EBorders,
  ERem,
  ESpacing,
  ESpacingFactor,
  Font,
} from "@hkexpressairwayslimited/ui/src";
import { Divider, Stack, Theme, styled } from "@mui/material";
import { useCallback } from "react";
import { returnStatus } from "../Stepper";
import { EStepperItemStatus, StepperItemData, StepperProps } from "./definition";

const StepperItemLabel = styled(Box)<{ checked: boolean }>(({ theme, checked }) => {
  return {
    borderRadius: "50%",
    height: ERem.rem2,
    width: ERem.rem2,
    border: `2px solid ${checked ? theme.palette.neutral.black : theme.palette.neutral.disable}`,
    textAlign: "center",
    lineHeight: ERem.rem2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});
function StepperItem({ label, desc, status, onClick }: { status: EStepperItemStatus } & StepperItemData) {
  const checked = status === EStepperItemStatus.checked;
  const active = status === EStepperItemStatus.active;
  const checkedColor = (theme: Theme): string =>
    checked || active ? theme.palette.neutral.black : theme.palette.neutral.disable;
  return (
    <>
      <Stack
        sx={{
          flex: "0 0 auto",
          cursor: "pointer",
        }}
        direction='row'
        alignItems='center'
        spacing={ESpacing._2xs}
        tabIndex={checked || active ? 0 : -1}
      >
        <StepperItemLabel checked={checked || active} onClick={() => onClick && onClick()}>
          {checked ? <CheckIcon /> : <Font sx={{ color: checkedColor }}>{label}</Font>}
        </StepperItemLabel>
        <Box
          onClick={() => onClick && onClick()}
          sx={{
            display: active ? "block" : ["none", "none", "block"],
          }}
        >
          <Font sx={{ color: checkedColor, mt: ESpacingFactor.px4, fontSize: "14px", fontWeight: "500" }}>{desc}</Font>
        </Box>
      </Stack>
      <Divider
        sx={{
          display: ["none", "none", "block"],
          borderColor: checkedColor,
          flex: "1",
        }}
      />
    </>
  );
}

export function DesktopStepper({
  steppers,
  onClick,
  index = 1,
  beforeItemClick = (currentIdx, idx) => idx < currentIdx,
}: StepperProps): JSX.Element {
  const handleItemClick = useCallback(
    (idx: number) => {
      if (!onClick) return undefined;
      if (beforeItemClick === undefined || beforeItemClick(index, idx) === true) {
        return onClick(idx);
      }
    },
    [beforeItemClick, onClick, index]
  );
  return (
    <Box
      sx={{
        paddingY: ESpacing._s,
        border: EBorders.b1,
        backgroundColor: "neutral.white",
        overflow: "auto",
      }}
    >
      <Container>
        <Stack
          direction='row'
          justifyContent={["flex-start", "flex-start", "space-between"]}
          alignItems='center'
          spacing={ESpacingFactor.px16}
          sx={{
            "& hr.MuiDivider-root:last-of-type": {
              display: "none",
            },
          }}
        >
          {steppers.map((stepper, number) => (
            <StepperItem
              key={stepper.label}
              onClick={() => handleItemClick(number + 1)}
              {...stepper}
              status={returnStatus(number + 1, index)}
            />
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
