import {
  Box,
  CardHeaderTypeMap,
  CardHeader as MuiCardHeader,
  CardHeaderProps as MuiCardHeaderProps,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Theme, styled } from "@mui/material/styles";
import { ReactNode, forwardRef } from "react";
import { propTrueValue } from "./common";
export type CardHeaderProps = {
  children?: ReactNode | string;
  fontcolor?: "default" | "primary";
  withBGC?: boolean | string | "primary-light";
} & MuiCardHeaderProps;

const StyledCardHeader = styled<OverridableComponent<CardHeaderTypeMap<CardHeaderProps, "div">>>(MuiCardHeader)(({
  theme,
  fontcolor,
}) => {
  const fontColor = fontcolor === "primary" ? theme.palette.purple.default : "inherit";
  return {
    padding: 0,
    color: fontColor,
  };
});

const StyledCardHeaderWrapper = styled(Box)(({ theme, withBGC }: CardHeaderProps & { theme: Theme }) => {
  const backgroundColor =
    withBGC === "primary-light"
      ? theme.palette.purple.surfaceSubdued
      : propTrueValue(withBGC)
        ? theme.palette.neutral.borderSubdued
        : "unset";
  return {
    backgroundColor,
  };
});

export const CardHeader = forwardRef<HTMLElement, CardHeaderProps>((props, ref) => {
  return (
    <StyledCardHeaderWrapper withBGC={props.withBGC} className='HeaderWrapper'>
      <StyledCardHeader {...props}></StyledCardHeader>
    </StyledCardHeaderWrapper>
  );
});
CardHeader.displayName = "CardHeader";
