import {
  Box,
  Button,
  CloseIcon,
  DateIcon,
  EPixel,
  Popper,
  PopperHandle,
  SvgIcon,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";
import { Divider, Stack } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { DateRangeCalendarProps } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { format } from "date-fns";
import { ForwardedRef, MouseEvent, forwardRef, useEffect, useState } from "react";
import { DateRangePicker } from "../DateRangePicker";

const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "relative",

    "& > div:last-child": {
      width: "100%",
      position: "relative",
    },
  };
});

const StyledDisabledBg = styled(Box)(({ isDisable }: { isDisable: boolean }) => {
  return {
    position: "absolute",
    background: isDisable ? "none" : "#f7f7f7",
    height: `calc(100% + ${EPixel.px24})`,
    width: `calc(100% + ${EPixel.px32})`,
    left: `-${EPixel.px16}`,
    boxSizing: "border-box",
  };
});

const today = new Date();

export const DateRangePickerField = forwardRef(
  (
    {
      popUpTitle = "",
      placeholder = [""],
      disabledEndDate = false,
      isSingleDate = false,
      isStartDateChange = false,
      onChange,
      isDisabled = false,
      ...rest
    }: DateRangeCalendarProps<Date> & {
      isSingleDate?: boolean;
      placeholder?: string[];
      disabledEndDate?: boolean;
      popUpTitle?: string;
      isStartDateChange?: boolean;
      isDisabled?: boolean;
      onChange?: (value: (Date | null)[]) => void;
    },
    ref: ForwardedRef<HTMLUListElement>
  ) => {
    const { isDesktop, isMobile } = useMediaQueries();
    const [popperHandle, setPopperHandle] = useState<PopperHandle | null>();
    const [dateValue, setDateValue] = useState<DateRange<Date>>(rest.value || [null, null]);
    const [clickCount, setClickCount] = useState<number>(0);
    const onAnchorClick = (event: MouseEvent<HTMLElement>) => {
      popperHandle?.open(event.currentTarget);
    };
    const handleDateChange = (value: any) => {
      setDateValue(value);
      if (isSingleDate || disabledEndDate) {
        if (value?.[0]) {
          onChange?.([value?.[0], null]);
          popperHandle?.close();
        }
      } else {
        onChange?.(value);
        setClickCount((prev) => prev + 1);
        if (value?.[0] && value?.[1] && clickCount >= 1) {
          setClickCount(0);
          popperHandle?.close();
        }
      }
    };
    const hidePopper = () => {
      popperHandle?.close();
    };
    useEffect(() => {
      rest.value && setDateValue(rest.value);
    }, [rest.value]);
    return (
      <>
        <Stack
          sx={{ width: "100%" }}
          onClick={onAnchorClick}
          direction={isDesktop ? "row" : "column"}
          alignItems='center'
          divider={
            isDesktop && !isSingleDate ? (
              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  margin: `0 ${EPixel.px16}`,
                  height: EPixel.px32,
                  width: "1px",
                  backgroundColor: "neutral.borderNeutral",
                }}
              />
            ) : null
          }
        >
          <StyledButton custom sx={{ mb: isDesktop || isSingleDate ? 0 : `${EPixel.px12} !important` }}>
            <Stack direction='row' alignItems='center'>
              <SvgIcon
                sx={{
                  width: EPixel.px32,
                  height: EPixel.px32,
                  mr: EPixel.px4,
                }}
              >
                <DateIcon />
              </SvgIcon>
              <Box
                sx={{
                  opacity: dateValue?.[0] ? 1 : 0.42,
                }}
              >
                {dateValue?.[0] ? `${format(dateValue?.[0], "dd/MM/yyyy")}` : placeholder?.[0]}
              </Box>
            </Stack>
          </StyledButton>
          {!isSingleDate && (
            <StyledButton custom sx={{ mt: isDesktop ? 0 : `${EPixel.px12} !important` }}>
              {disabledEndDate && (
                <StyledDisabledBg
                  sx={{
                    borderRadius: isDesktop ? `0 ${EPixel.px8} ${EPixel.px8} 0` : `0 0 ${EPixel.px8} ${EPixel.px8}`,
                  }}
                  isDisable={isDisabled}
                />
              )}
              <Stack direction='row' alignItems='center'>
                <SvgIcon
                  sx={{
                    width: EPixel.px32,
                    height: EPixel.px32,
                    mr: EPixel.px4,
                    color: disabledEndDate ? "#E5E5E5" : "neutral.black",
                  }}
                >
                  <DateIcon />
                </SvgIcon>
                <Box sx={{ opacity: dateValue?.[1] ? 1 : 0.42 }}>
                  {dateValue?.[1] ? `${format(dateValue?.[1], "dd/MM/yyyy")}` : placeholder?.[1]}
                </Box>
              </Stack>
            </StyledButton>
          )}
        </Stack>
        <Popper
          sx={{
            transform: !isMobile ? "translate(-50%, 0) !important" : "initial !important",
            position: "absolute",
            zIndex: 9998,
            inset: "initial !important",
            top: "0 !important",
            left: "50% !important",
            paddingTop: "43px",
            "& .arrowWrapper": {
              bottom: "initial !important",
            },
            "& .arrow": {
              transform: "rotate(45deg) !important",
              marginBottom: "initial !important",
              marginTop: "40px !important",
            },
            "& .popper-content": {
              marginTop: "48px !important",
              marginBottom: "initial !important",
            },
          }}
          arrow
          focusOutClose
          ref={setPopperHandle}
          gap={36}
        >
          <Box
            sx={
              !isMobile
                ? {}
                : {
                    position: "fixed",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "neutral.white",
                    overflowY: "auto",
                    padding: "22px 14px",
                    boxSizing: "border-box",
                    zIndex: 9999,
                  }
            }
          >
            <Stack
              sx={{
                display: !isMobile ? "none !important" : "flex !important",
                mb: !isMobile ? 0 : EPixel.px24,
              }}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box sx={{ fontSize: EPixel.px20, fontWeight: "bold" }}>{popUpTitle}</Box>
              <SvgIcon onClick={hidePopper} sx={{ width: EPixel.px32, height: EPixel.px32, cursor: "pointer" }}>
                <CloseIcon />
              </SvgIcon>
            </Stack>
            <DateRangePicker
              {...rest}
              availableRangePositions={isSingleDate || disabledEndDate ? ["start"] : ["start", "end"]}
              value={dateValue}
              onChange={handleDateChange}
            />
          </Box>
        </Popper>
      </>
    );
  }
);

DateRangePickerField.displayName = "DateRangePickerField";
