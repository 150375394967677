import { AppBar, styled } from "@mui/material";
import clsx from "clsx";

import {
  Dispatch,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  AccountIcon,
  AppContext,
  Button,
  ButtonMenu,
  ButtonMenuItem,
  ButtonMenuItemProps,
  ButtonVariant,
  CaretLeft2Icon,
  CaretRightIcon,
  Coin2Icon,
  Divider,
  Drawer,
  Dropdown,
  EPixel,
  LanguageIcon,
  Link,
  LinkProps,
  MenuIcon,
  Popper,
  PopperHandle,
  Section,
  SelectOption,
  SvgIcon,
  SvgIconSize,
  Text,
  TextSize,
  Title,
  TitleOrders,
  colors,
  useDesktopQuery,
  usePlaceholderChildGroups,
} from "@hkexpressairwayslimited/ui/src";
import currency from "currency.js";
import { AlertMessageBar, AlertMessageBarProps } from "./AlertMessageBar";
import classes from "./Header.module.scss";

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  zIndex: 900,
}));

const MobileDrawer = styled(Drawer)(({ theme }) => ({
  padding: 0,
}));

const HeaderContext = createContext<{
  header?: HTMLElement | null;
  mouseEnteredHeader: boolean;
  mainNavMenuPopper?: PopperHandle | null;
  setMainNavMenuPopper?: Dispatch<SetStateAction<PopperHandle | null | undefined>>;
  handleHeaderDrawer?: () => void;
  isDesktop?: boolean;
}>({
  mouseEnteredHeader: false,
});

export type NavMenuLinkProps = LinkProps;

const NavMenuLink = ({ children, ...others }: NavMenuLinkProps) => {
  return (
    <Link className={classes.navMenu_link} color={colors.neutralBlack} unbold {...others}>
      {children}
    </Link>
  );
};

const NavMenuColumn = ({ children }: { children?: ReactNode }) => {
  return <div className={classes.navMenu_groupColumn}>{children}</div>;
};

export type NavMenuGroupProps = {
  groupLink: ReactElement;
  children?: ReactNode;
};

const NavMenuGroup = ({ groupLink, children }: NavMenuGroupProps) => {
  const { handleHeaderDrawer, isDesktop } = useContext(HeaderContext);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const { children: actualChildren } = usePlaceholderChildGroups(children);

  const handleClickGroupLink = (event: MouseEvent<HTMLDivElement>) => {
    if (!isDesktop) {
      handleOpenDrawer();
      if (!actualChildren) {
        event.stopPropagation();
      }
    }
  };

  const handleCloseLinkList = (event: MouseEvent<HTMLButtonElement>) => {
    if (!isDesktop) {
      handleCloseDrawer();
      event.stopPropagation();
    }
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpened(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpened(false);
  };

  return (
    <div className={classes.navMenu_group}>
      <div className={classes.navMenu_groupLink} onClick={handleClickGroupLink}>
        {groupLink}
        <SvgIcon sx={{ fontSize: `${EPixel.px24} !important` }}>
          <CaretRightIcon />
        </SvgIcon>
      </div>

      {isDesktop ? (
        <div className={classes.navMenu_linkList}>{children}</div>
      ) : (
        children && (
          <MobileDrawer
            hideBackdrop
            open={isDrawerOpened}
            onClose={handleHeaderDrawer}
            noPadding
            noBorderRadius
            fullWidth
          >
            <Button className={classes.drawer_navHeader} onClick={handleCloseLinkList} custom>
              <SvgIcon size={SvgIconSize.DenseLayout}>
                <CaretLeft2Icon />
              </SvgIcon>
              {groupLink}
            </Button>
            {children}
          </MobileDrawer>
        )
      )}
    </div>
  );
};

export type NavItemProps = {
  label: string | ReactElement;
  navMenuGroups?: ReactNode;
  horizontal?: boolean;
};

const NavItem = ({ label, navMenuGroups, horizontal = false }: NavItemProps) => {
  const { isInEditor } = useContext(AppContext);
  const { header, mouseEnteredHeader, mainNavMenuPopper, setMainNavMenuPopper, handleHeaderDrawer, isDesktop } =
    useContext(HeaderContext);
  const [popperHandle, setPopperHandle] = useState<PopperHandle | null>();
  const [mouseEnteredNav, setMouseEnteredNav] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const handleOpenNavMenu = () => {
    if (isDesktop) {
      if (header) {
        console.log("mainNavMenuPopper", mainNavMenuPopper, popperHandle, mainNavMenuPopper === popperHandle);
        if (popperHandle?.showPanel) {
          handleCloseNavMenu();
        } else {
          mainNavMenuPopper?.close();
          popperHandle?.open(header);
          setMainNavMenuPopper?.(popperHandle);
        }
      }
    } else {
      setIsDrawerOpened(true);
    }
  };

  const handleCloseNavMenu = () => {
    if (isDesktop) {
      popperHandle?.close();
      setMainNavMenuPopper?.(null);
    } else {
      setIsDrawerOpened(false);
    }
  };

  useEffect(() => {
    if (isDesktop) {
      if (!isInEditor && mouseEnteredNav && !popperHandle?.showPanel) {
        handleOpenNavMenu();
      }
    }
  }, [mouseEnteredNav]);

  useEffect(() => {
    if (isDesktop) {
      if (!isInEditor && !mouseEnteredNav && !mouseEnteredHeader) {
        handleCloseNavMenu();
      }
    }
  }, [mouseEnteredNav, mouseEnteredHeader]);

  return (
    <>
      <Button
        className={classes.header_navItem}
        variant={isDesktop ? ButtonVariant.Text : undefined}
        custom={!isDesktop}
        onClick={!isDesktop ? handleOpenNavMenu : undefined}
        onMouseEnter={() => setMouseEnteredNav(true)}
        onMouseLeave={() => setMouseEnteredNav(false)}
      >
        {isDesktop ? (
          <Title onClick={handleOpenNavMenu} order={TitleOrders.H3Title} color={colors.neutralBlack}>
            {label}
          </Title>
        ) : (
          <>
            <Text color={colors.neutralBlack}>{label}</Text>
            <SvgIcon size={SvgIconSize.DenseLayout} color={colors.neutralBlack}>
              <CaretRightIcon />
            </SvgIcon>
          </>
        )}
        {isDesktop && (
          <Popper
            sx={{
              inset: "100% auto 0 0 !important",
              transform: "none !important",
            }}
            ref={setPopperHandle}
            baseUi
            keepMounted
          >
            <div className={classes.navMenu}>
              <Section className={classes.navMenu_section}>
                <nav
                  className={clsx(classes.navMenu_groupList, {
                    [classes.navMenu_groupList__horizontal]: horizontal,
                  })}
                >
                  {navMenuGroups}
                </nav>
              </Section>
            </div>
          </Popper>
        )}
      </Button>
      {!isDesktop && (
        <MobileDrawer
          hideBackdrop
          open={isDrawerOpened}
          onClose={handleHeaderDrawer}
          noPadding
          noBorderRadius
          fullWidth
        >
          <Button className={classes.drawer_navHeader} onClick={handleCloseNavMenu} custom>
            <SvgIcon size={SvgIconSize.DenseLayout}>
              <CaretLeft2Icon />
            </SvgIcon>
            {label}
          </Button>
          {navMenuGroups}
        </MobileDrawer>
      )}
    </>
  );
};

enum HeaderVariants {
  Booking = "Booking",
  AgentPortal = "AgentPortal",
}

export interface HeaderPropsType {
  variant?: HeaderVariants;
  logo: {
    header: ReactElement;
    mobileMenu: ReactElement;
  };
  onMobileAccountClick: () => void;
  navItems?: ReactNode;
  alertMessageBarProps?: AlertMessageBarProps;
  showAlertMessageBar?: boolean;
  localeOptions?: (SelectOption & { onChange?: (value: SelectOption) => void })[];
  localeValue?: string;
  currencyOptions?: (SelectOption & { onChange?: (value: SelectOption) => void })[];
  currencyValue?: string;
  loginLabel?: ReactNode;
  logoutLabel?: ReactNode;
  onLogout?: () => void;
  signInMenu?: { label: ReactNode; itemProps?: ButtonMenuItemProps }[];
  membershipMenu?: { label: ReactNode; itemProps?: ButtonMenuItemProps }[];
  profile?: {
    title?: string;
    lastName?: string;
    fullName: string;
    balance?: string;
    credit_account?: {
      accountReference: string;
      amount: number;
      currencyCode: string;
      foreignAmount: number;
      foreignCurrencyCode: string;
    };
  };
  disableLogout?: boolean;
}

export const Header = (props: HeaderPropsType) => {
  const {
    variant = HeaderVariants.Booking,
    navItems,
    logo,
    onMobileAccountClick,
    alertMessageBarProps = {},
    showAlertMessageBar,
    localeValue,
    localeOptions,
    currencyValue,
    currencyOptions,
    loginLabel,
    logoutLabel,
    onLogout,
    profile,
    signInMenu,
    membershipMenu,
    disableLogout,
  } = props;
  const { isInEditor } = useContext(AppContext);
  const [header, setHeader] = useState<HTMLElement | null>(null);
  const [membership, setMembership] = useState<HTMLElement | null>(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [mouseEnteredHeader, setMouseEnteredHeader] = useState(false);
  const [mainNavMenuPopper, setMainNavMenuPopper] = useState<PopperHandle | null>();
  const [popperHandle, setPopperHandle] = useState<PopperHandle | null>();
  const [editingMenuContent, setEditingMenuContent] = useState(false);
  const [isMemberDrawer, setIsMemberDrawer] = useState(false);
  const isDesktop = useDesktopQuery();

  const handleOpenDrawer = (isMemberDrawer = false) => {
    setIsMemberDrawer(isMemberDrawer);
    setIsDrawerOpened(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpened(false);
  };

  const handleClick = () => {
    handleOpenDrawer(true);
    if (isInEditor && popperHandle?.showPanel) {
      if (editingMenuContent) {
        setEditingMenuContent(false);
      } else {
        setEditingMenuContent(true);
      }
    } else if (membership) {
      popperHandle?.open(membership);
    }
  };

  const handleClose = () => {
    popperHandle?.close();
  };

  const localeDropdown = localeOptions && (
    <Dropdown
      hideArrow
      options={localeOptions as SelectOption[]}
      onChange={(value: string) => {
        const option = localeOptions.find((option) => option.value === value);

        if (option?.onChange) {
          const { onChange, ...otherOptionProps } = option;

          option.onChange(otherOptionProps);
        }
      }}
      value={localeValue}
    />
  );
  const currencyDropdown = currencyOptions && (
    <Dropdown
      hideArrow
      onDisplaySelection={(option) => {
        return option?.value;
      }}
      options={currencyOptions as SelectOption[]}
      onChange={(value: string) => {
        const option = currencyOptions.find((option) => option.value === value);

        if (option?.onChange) {
          const { onChange, ...otherOptionProps } = option;

          option.onChange(otherOptionProps);
        }
      }}
      value={currencyValue}
    />
  );

  const membershipSettingLabel = profile
    ? variant === HeaderVariants.Booking
      ? `${profile.title} ${profile.lastName}`
      : profile.fullName
    : loginLabel;
  const menuHasProfile = isInEditor ? editingMenuContent : profile;

  const menuHeader = (
    <div className={classes.header_menuHeader} style={{ display: !menuHasProfile ? "none" : undefined }}>
      <div className={classes.header_menuHeaderInfo}>
        <Text size={TextSize.P1Medium}>{profile?.fullName}</Text>
        {profile?.credit_account && (
          <Title order={TitleOrders.H3Title}>
            {`${profile.credit_account.currencyCode} ${currency(profile.credit_account.amount, {
              precision: profile.credit_account.amount.toString().includes(".") ? 2 : 0,
            }).format({
              symbol: "",
              separator: ",",
              decimal: ".",
            })}`}
          </Title>
        )}
      </div>
      {!disableLogout ? (
        <Button variant={ButtonVariant.Secondary} onClick={onLogout}>
          {logoutLabel}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );

  const appBarContent = (
    <div
      className={clsx({ [classes.header__mobile]: !isDesktop })}
      onMouseEnter={() => setMouseEnteredHeader(true)}
      onMouseLeave={() => setMouseEnteredHeader(false)}
    >
      <div className={classes.header_barWrapper} ref={setHeader}>
        <Section>
          <div className={classes.header_bar}>
            <div className={classes.header_logo}>{logo.header}</div>
            {isDesktop && navItems}
            <div className={classes.header_settings}>
              {isDesktop && (
                <div className={classes.header_appSettings}>
                  {localeOptions && (
                    <div className={classes.header_localeField}>
                      <SvgIcon sx={{ fontSize: `${EPixel.px20} !important` }}>
                        <LanguageIcon />
                      </SvgIcon>
                      {localeDropdown}
                    </div>
                  )}
                  {currencyOptions && localeOptions && <Divider inline vertical />}
                  {currencyOptions && (
                    <div className={classes.header_currencyField}>
                      <SvgIcon sx={{ fontSize: `${EPixel.px20} !important` }}>
                        <Coin2Icon />
                      </SvgIcon>
                      {currencyDropdown}
                    </div>
                  )}
                </div>
              )}
              <div className={classes.header_membership} ref={setMembership}>
                {membershipSettingLabel && (
                  <>
                    {isDesktop && <Divider inline vertical color={colors.neutralActionDisabledSurface} />}
                    {(isDesktop || (!isDesktop && profile)) && (
                      <>
                        <Button variant={ButtonVariant.Text} leadingIcon={<AccountIcon />} onClick={handleClick}>
                          {membershipSettingLabel}
                        </Button>
                        {isDesktop && (
                          <ButtonMenu
                            tabIndex={1}
                            dividers={!menuHasProfile}
                            className={clsx({
                              [classes.header_signInMenu]: !menuHasProfile,
                              [classes.header_membershipMenu]: menuHasProfile,
                            })}
                            ref={setPopperHandle}
                            header={menuHasProfile && menuHeader}
                            popperProps={{ keepMounted: isInEditor, focusOutClose: !isInEditor }}
                          >
                            <>
                              {signInMenu?.map((item, index) => (
                                <ButtonMenuItem
                                  tabIndex={-1}
                                  key={index}
                                  {...(item.itemProps || {})}
                                  style={{ display: menuHasProfile ? "none" : undefined }}
                                  className={classes.header_menuItem}
                                >
                                  {item.label}
                                </ButtonMenuItem>
                              ))}
                              {membershipMenu?.map((item, index) => (
                                <ButtonMenuItem
                                  tabIndex={-1}
                                  key={index}
                                  {...(item.itemProps || {})}
                                  style={{ display: !menuHasProfile ? "none" : undefined }}
                                  className={classes.header_menuItem}
                                >
                                  {item.label}
                                </ButtonMenuItem>
                              ))}
                            </>
                          </ButtonMenu>
                        )}
                        {/* <Divider inline vertical color={colors.neutralActionDisabledSurface} /> */}
                      </>
                    )}

                    {!isDesktop && !profile && (
                      <Button
                        onClick={onMobileAccountClick}
                        className={classes.header_navBtn}
                        iconSize={SvgIconSize.Desktop}
                        variant={ButtonVariant.Navigation}
                      >
                        <AccountIcon />
                      </Button>
                    )}
                  </>
                )}
                {!isDesktop && (
                  <Button
                    onClick={() => handleOpenDrawer()}
                    className={classes.header_navBtn}
                    iconSize={SvgIconSize.Desktop}
                    variant={ButtonVariant.Navigation}
                  >
                    <MenuIcon />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Section>
      </div>

      {variant === HeaderVariants.Booking && (showAlertMessageBar || isInEditor) && (
        <AlertMessageBar {...alertMessageBarProps} />
      )}
    </div>
  );

  return (
    <HeaderContext.Provider
      value={{
        header,
        mouseEnteredHeader,
        mainNavMenuPopper,
        setMainNavMenuPopper,
        handleHeaderDrawer: handleCloseDrawer,
        isDesktop,
      }}
    >
      <HeaderAppBar position='sticky' color='inherit'>
        {appBarContent}
        {!isDesktop && (
          <MobileDrawer
            className={classes.drawer}
            open={isDrawerOpened}
            onClose={handleCloseDrawer}
            noPadding
            noBorderRadius
            fullWidth
          >
            <div className={classes.drawer_header}>
              <div className={classes.header_logo}>{logo.mobileMenu}</div>
            </div>
            {isMemberDrawer ? (
              <>
                <div className={classes.drawer_allSettings}>{menuHeader}</div>
                {membershipMenu?.map((item, index) => (
                  <Button custom key={index} {...(item.itemProps || {})} className={classes.drawer_setting}>
                    {item.label}
                  </Button>
                ))}
              </>
            ) : (
              <>
                {navItems}
                <div className={classes.drawer_allSettings}>
                  {localeDropdown && (
                    <Button custom className={classes.drawer_setting}>
                      <SvgIcon sx={{ fontSize: `${EPixel.px20} !important` }}>
                        <LanguageIcon />
                      </SvgIcon>
                      {localeDropdown}
                    </Button>
                  )}
                  {currencyDropdown && (
                    <Button custom className={classes.drawer_setting}>
                      <SvgIcon sx={{ fontSize: `${EPixel.px20} !important` }}>
                        <Coin2Icon />
                      </SvgIcon>
                      {currencyDropdown}
                    </Button>
                  )}
                </div>
              </>
            )}
          </MobileDrawer>
        )}
      </HeaderAppBar>
    </HeaderContext.Provider>
  );
};

Header.NavItem = NavItem;
Header.NavMenuGroup = NavMenuGroup;
Header.NavMenuColumn = NavMenuColumn;
Header.NavMenuLink = NavMenuLink;
Header.HeaderContext = HeaderContext;
Header.AlertMessageBar = AlertMessageBar;
Header.Variants = HeaderVariants;
