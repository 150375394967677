/* eslint-disable react/jsx-key */
import {
  AccordionExpandIcon,
  AppContext,
  Button,
  EMAIL_REGEXP,
  EPixel,
  ErrorLabel,
  FieldInputGroup,
  FieldInputGroupProps,
  Form,
  Link,
  LinkProps,
  Section,
  Text,
  TextInput,
  TextSize,
  Title,
  TitleOrders,
  colors,
  useDesktopQuery,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from "@mui/material";
import { ReactElement, ReactNode, useContext } from "react";
import * as yup from "yup";
import classes from "./Footer.module.scss";
const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  "&:before": {
    display: "none",
  },
  boxShadow: "none",
  backgroundColor: "transparent",
  "&.Mui-expanded": {
    margin: 0,
  },
  ".MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "initial",
  },
  ".MuiAccordionSummary-root": {
    minHeight: "initial",
    padding: `0 ${EPixel.px24}`,
    [theme.breakpoints.down("md")]: {
      padding: `0 ${EPixel.px16}`,
    },
  },
  ".MuiAccordionSummary-content": {
    margin: `0`,
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: `0`,
  },
  ".MuiAccordionDetails-root": {
    padding: `${EPixel.px12} ${EPixel.px24} 0 ${EPixel.px24}`,
    [theme.breakpoints.down("md")]: {
      padding: `${EPixel.px20} ${EPixel.px16} 0 ${EPixel.px16}`,
    },
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    width: EPixel.px32,
    height: EPixel.px32,
    color: "#702B91",
    transition: "none",
  },
}));
export type FooterProps = {
  subscriptionField: {
    label: string | ReactElement;
    helperText: FieldInputGroupProps["helperText"];
  };
  subscribeBtnLabel: string | ReactElement;
  logo: ReactElement;
  copyrightLabel: string | ReactNode;
  navGroups?: ReactNode;
  socialShareTitle?: string | ReactElement;
  socialShareChannels?: ReactNode;
  emailFieldRequiredMsg: yup.Message<any>;
  editor: {
    emailFieldRequiredMsg?: ReactNode;
  };
};

export type FooterNavGroupProps = {
  groupLabel: string | ReactElement;
  navLinks: ReactNode;
};

export type FooterNavLinkProps = LinkProps;

export const FooterNavLink = (props: FooterNavLinkProps) => {
  return <Link size={TextSize.P2Regular} color={colors.neutralBlack} unbold {...props} />;
};

export const FooterNavGroup = ({ groupLabel, navLinks }: FooterNavGroupProps) => {
  const isDesktop = useDesktopQuery();
  return (
    <div className={classes.navGroup}>
      {isDesktop ? (
        <>
          <Text span bold color={colors.neutralBlack}>
            {groupLabel}
          </Text>
          <div className={classes.navGroup_navLinks}>{navLinks}</div>
        </>
      ) : (
        <StyledMuiAccordion>
          <MuiAccordionSummary expandIcon={<AccordionExpandIcon />}>
            <Text span bold color={colors.neutralBlack}>
              {groupLabel}
            </Text>
          </MuiAccordionSummary>
          <MuiAccordionDetails>
            <div className={classes.navGroup_navLinks}>{navLinks}</div>
          </MuiAccordionDetails>
        </StyledMuiAccordion>
      )}
    </div>
  );
};

export type FooterShareChannelProps = {
  children: ReactElement;
};

export const FooterShareChannel = ({ children }: FooterShareChannelProps) => {
  return <div className={classes.footer_shareChannel}>{children}</div>;
};

export const Footer = ({
  copyrightLabel,
  logo,
  subscriptionField,
  subscribeBtnLabel,
  socialShareTitle,
  socialShareChannels,
  navGroups,
  emailFieldRequiredMsg,
  editor,
}: FooterProps) => {
  const { isInEditor } = useContext(AppContext);
  const isDesktop = useDesktopQuery();
  enum Fields {
    Email = "email",
  }
  const formHook = useForm<{}>({
    defaultValues: {},
    schema: yup.object().shape({
      [Fields.Email]: yup.string().matches(EMAIL_REGEXP, emailFieldRequiredMsg).required(emailFieldRequiredMsg),
    }),
  });
  const navList = <nav className={classes.footer_navList}>{navGroups}</nav>;
  const newsSubscription = (
    <div className={classes.footer_newsSubscription}>
      <Title order={TitleOrders.H3Title} color={colors.neutralBlack}>
        {subscriptionField.label}
      </Title>
      <div className={classes.footer_subscribeRow}>
        {isDesktop && (
          <>
            <div className={classes.footer_subscribeRow_flex}>
              <FieldInputGroup helperText={subscriptionField.helperText} names={[Fields.Email]} fullWidth>
                <TextInput fullWidth placeholder={subscriptionField.helperText} type='email' />
              </FieldInputGroup>
              {isInEditor && editor.emailFieldRequiredMsg && <ErrorLabel>{editor.emailFieldRequiredMsg}</ErrorLabel>}
            </div>
            <Button>{subscribeBtnLabel}</Button>
          </>
        )}
      </div>
      {!isDesktop && (
        <>
          <div>
            <FieldInputGroup helperText={subscriptionField.helperText} names={[Fields.Email]} fullWidth>
              <TextInput fullWidth placeholder={subscriptionField.helperText} type='email' />
            </FieldInputGroup>
            {isInEditor && editor.emailFieldRequiredMsg && <ErrorLabel>{editor.emailFieldRequiredMsg}</ErrorLabel>}
          </div>
          <Button>{subscribeBtnLabel}</Button>
        </>
      )}
    </div>
  );
  const socialShare = (
    <div className={classes.footer_socialShare}>
      <Text color={colors.neutralBlack} size={TextSize.P1Medium}>
        {socialShareTitle}
      </Text>
      <div className={classes.footer_shareChannels}>{socialShareChannels}</div>
    </div>
  );

  return (
    <div>
      <Form formHook={formHook}>
        <div className={classes.footer}>
          {isDesktop && (
            <Section>
              <div className={classes.footer_upper}>
                {navList}
                <div className={classes.footer_communication}>
                  {newsSubscription}
                  {socialShare}
                </div>
              </div>
            </Section>
          )}
          {!isDesktop && (
            <>
              <Section fullWidth>{navList}</Section>
              <Section fullWidth>{newsSubscription}</Section>
              <Section fullWidth>{socialShare}</Section>
            </>
          )}

          <div className={classes.footer_lowerWrapper}>
            <Section>
              <div className={classes.footer_lower}>
                <div className={classes.footer_logo}>{logo}</div>
                <Text size={TextSize.P3Medium} span color={colors.neutralWhite}>
                  {copyrightLabel}
                </Text>
              </div>
            </Section>
          </div>
        </div>
      </Form>
    </div>
  );
};
