import { EFont } from "@hkexpressairwayslimited/ui/src/themes/typography";
import { Box } from "@mui/material";
import { Button } from "../../Atoms/Button";
import { Font } from "../../Atoms/Font/Font";
import { Image } from "../../Atoms/Image";
export interface PartnerProps {
  desc: string;
  url: string;
  text: string;
}
const PartnerOffer = (props: PartnerProps) => {
  return (
    <>
      <Box
        border={"1px solid #D6D6D8"}
        borderRadius={4}
        bgcolor={"#ffffff"}
        minWidth={298}
        flex={"0 0 calc(33.3333% - 13px)"}
        overflow={"hidden"}
      >
        <Box height={267} bgcolor={"#d9d9d9"} position={"relative"}>
          <Image alt='banner' src={props.url} fill height={267} width={"100%"} />
        </Box>
        <Box padding={3} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
          <Box mt={2}>
            <Font variant={EFont.p1}>{props.desc}</Font>
          </Box>
          <Box mt={2}>
            <Button outlined>{props.text}</Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { PartnerOffer };
