import { Box, Card, CardContent, Text, TextSize, Title, TitleOrders, Toggle } from "@hkexpressairwayslimited/ui/src";
import { useEffect, useMemo, useState } from "react";

import { MilesPlusCashPaymentContext, MilesPlusCashPaymentOptionsSteps } from "./MilesPlusCashPaymentContext";

import classes from "./MilesPlusCashPaymentOptions.module.scss";
import { MilesPlusCashForm } from "./steps";
import { MilesPlusCashView } from "./steps/MilesPlusCashView";

export type MilesPlusCashPaymentOptionsProps = {
  title: string;
  desc: string;
  totalMilesAvailableLabel: string;
  totalMilesAvailable: number;
  flightAndTravelExtraField: {
    placeholder: string;
    title: string;
    desc: string;
    note: string;
  };
  formStep: {
    submitBtnLabel: string;
    skipBtnLabel: string;
  };
  viewStep: {
    appliedItemName: string;
    removeBtnLabel: string;
  };
  order: MPCOrderProps;
  profile: {
    name: string;
    tierLabel: string;
    memberNo: string;
  };
  onMilesChange?: (miles: number) => void; //React.Dispatch<React.SetStateAction<number>>;
  onMilesToCashAmountChange?: (cash: number) => void; //React.Dispatch<React.SetStateAction<number>>;
  onEnable?: (cur: boolean) => void;
  onConfirm?: (miles: number, cashAmount: number) => void;
  onRemove?: () => void;
  step?: MilesPlusCashPaymentOptionsSteps;
  enabled?: boolean;
  disabled?: boolean;
  confirmRequiredError?: boolean;
};

export type MPCOrderProps = {
  currency: string;
  cost: number;
  minSpendingMiles?: number;
  maxSpendingMiles?: number;
  maxCashEqAmount: number;
  currencyConversionRate?: number;
  milesBands?: MPCBrands[];
  exchangeAll?: boolean;
};

export type MPCBrands = {
  from: number;
  rate: number;
};

export const MilesPlusCashPaymentOptions = (props: MilesPlusCashPaymentOptionsProps) => {
  const {
    title,
    totalMilesAvailableLabel,
    totalMilesAvailable,
    desc,
    onMilesChange,
    onMilesToCashAmountChange,
    step: OStep,
    enabled: OEnable,
    disabled,
    onEnable,
    onConfirm,
    onRemove,
    order,
  } = props;
  const [step, setStep] = useState(OStep ?? MilesPlusCashPaymentOptionsSteps.Form);
  const [milesUsed, setMilesUsed] = useState(0);
  const [milesUsedInCash, setMilesUsedInCash] = useState(0);
  const [enabled, setEnabled] = useState(OEnable ?? false);

  const readyOnly = useMemo(() => {
    return Boolean(!order.milesBands);
  }, [order.milesBands]);
  useEffect(() => {
    OEnable !== undefined && setEnabled(OEnable);
    if (OEnable === false) {
      setMilesUsed(0);
      setMilesUsedInCash(0);
      onMilesChange && onMilesChange(0);
      onMilesToCashAmountChange && onMilesToCashAmountChange(0);
      setStep(MilesPlusCashPaymentOptionsSteps.Form);
    }
  }, [OEnable]);
  useEffect(() => {
    OStep !== undefined && setStep(OStep);
  }, [OStep]);

  useEffect(() => {
    onMilesChange && onMilesChange(milesUsed);
    onMilesToCashAmountChange && onMilesToCashAmountChange(milesUsedInCash);
  }, [milesUsed]);

  const [contextValue, setContextValue] = useState({
    step,
    setStep,
    milesUsed,
    setMilesUsed,
    milesUsedInCash,
    setMilesUsedInCash,
    readyOnly,
    ...props,
  });

  useEffect(() => {
    setContextValue({
      step,
      setStep,
      milesUsed,
      setMilesUsed,
      milesUsedInCash,
      setMilesUsedInCash,
      onConfirm,
      onRemove,
      readyOnly,
      ...props,
    });
  }, [props, step, setStep, milesUsed, setMilesUsed, milesUsedInCash]);

  return (
    <Card>
      <CardContent>
        <Box className={classes.milesPlusCashPaymentOptions_titleWrapper}>
          <Title order={TitleOrders.H3Title} color='#8f8367'>
            {title}
          </Title>
          {!readyOnly && (
            <Toggle
              checked={enabled}
              disabled={disabled}
              onChange={(event) => {
                onEnable ? onEnable(enabled) : setEnabled(event.target.checked);
              }}
            />
          )}
        </Box>
        <Text size={TextSize.Body2} className={classes.milesPlusCashPaymentOptions_desc}>
          {desc}
        </Text>
        {enabled && (
          <MilesPlusCashPaymentContext.Provider value={contextValue}>
            {step === MilesPlusCashPaymentOptionsSteps.Form && <MilesPlusCashForm />}
            {step === MilesPlusCashPaymentOptionsSteps.View && <MilesPlusCashView />}
          </MilesPlusCashPaymentContext.Provider>
        )}
      </CardContent>
    </Card>
  );
};
