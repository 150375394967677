import { Box, EFont, ESpacing, Font, Tag } from "@hkexpressairwayslimited/ui/src";
import clsx from "clsx";
import { ReactElement } from "react";
import classes from "./SummaryBox.module.scss";

type ClassType = "Ultra Lite" | "Lite" | "Essential" | "Max";
interface SummaryBoxProps {
  data: any;
  margin?: boolean;
  borderBottom?: boolean;
}

interface BreakDownItemProps {
  subject: string;
  value: string | ReactElement;
  type?: "Font" | "FontNoBorder";
  classLabel?: ClassType;
  tag?: boolean;
  show?: boolean;
}

const BreakDownItem = ({ subject, value, type, classLabel, tag, show }: BreakDownItemProps) => {
  if (show !== undefined && show === false) {
    return <></>;
  }

  return (
    <Box
      className={clsx(classes.container_subContainer_item, {
        [classes.container_subContainer_border]: type === "Font",
      })}
    >
      <Box className={classes.container_subContainer_subjectContainer}>
        <Font
          color='black'
          variant={type === "Font" || type === "FontNoBorder" ? EFont.p1 : EFont.p2}
          sx={
            type === "Font" || type === "FontNoBorder"
              ? { fontWeight: 700, whiteSpace: "nowrap" }
              : { whiteSpace: "nowrap" }
          }
        >
          {subject}
        </Font>
        {classLabel && tag ? (
          <Tag value={classLabel} variant='promo_message' sx={{ marginLeft: ESpacing._2xs }} />
        ) : null}
      </Box>
      <Box className={classes.container_subContainer_subjectContainer}>
        {value === "Approved" && typeof value === "string" ? (
          <Tag variant='success' value={value} sx={{ marginLeft: "8px" }} />
        ) : subject === "Payment type" && typeof subject !== "string" ? (
          value
        ) : (
          <Font
            color='black'
            variant={type === "Font" || type === "FontNoBorder" ? EFont.p1 : EFont.p2}
            sx={
              type === "Font" || type === "FontNoBorder"
                ? { fontWeight: 700, textAlign: "right" }
                : { textAlign: "right" }
            }
          >
            {value}
          </Font>
        )}
      </Box>
    </Box>
  );
};

const SummaryBox = ({ data, margin, borderBottom }: SummaryBoxProps) => {
  return (
    <Box className={classes.container}>
      {data.map((item: any, i: number) => (
        <Box key={i}>
          {item.subItem ? (
            <Box
              className={clsx(classes.container_subContainer, {
                [classes.container_subContainer_marginBottom]: margin,
              })}
            >
              {item.type && item.headingValue ? (
                <BreakDownItem subject={item.type} value={item.headingValue} type='Font' />
              ) : (
                <></>
              )}
              <Box
                className={clsx(classes.container_subContainer, {
                  [classes.container_subContainer_borderBottom]: borderBottom && i === data.length - 1,
                })}
              >
                {item.subItem.map((subItem: any, i: number) => (
                  <BreakDownItem
                    key={`${subItem.label}-${i}`}
                    subject={subItem.label}
                    value={subItem.value}
                    classLabel={item?.class}
                    tag={subItem?.tag}
                    show={subItem?.show}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              className={clsx(classes.container_subContainer, {
                [classes.container_subContainer_marginBottom]: margin,
              })}
            >
              <BreakDownItem subject={item.type} value={item.headingValue} type='FontNoBorder' />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export { SummaryBox };
