import { AlipayIcon, SvgIcon, VisaIcon } from "@hkexpressairwayslimited/ui/src";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import classes from "./PaymentOptionsSelect.module.scss";

interface PaymentOptionsSelectProps {
  title: string;
  subTitles: string[];
  paymentOptions: string[];
  setPaymentMethod: (paymentMethod: string) => void;
}

const PaymentOptionsSelect = ({ title, subTitles, paymentOptions, setPaymentMethod }: PaymentOptionsSelectProps) => {
  const optionsData = [
    { name: "VI", icon: <VisaIcon />, type: "card" },
    { name: "AI", icon: <AlipayIcon />, type: "other" },
  ];

  const [selected, setSelected] = useState<string>("");

  const handleSelectPayment = (paymentMethod: string) => {
    setSelected(paymentMethod);
    setPaymentMethod(paymentMethod);
  };
  console.log("PaymentOptionsSelect in last page", paymentOptions);
  return (
    <Grid item xs={12} sx={{ border: "1px solid lightgrey" }}>
      <Box padding={4}>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <Typography variant='h6' color={"#702B91"}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.paymentOptionsSelect}>
              <span>{subTitles[0]}</span>
              <Box className={classes.paymentOptionsSelect_paymentTitle}>
                {paymentOptions.map((option: any) => {
                  const paymentIcon = optionsData.find((optionData) => optionData.name === option);
                  if (paymentIcon && paymentIcon.type === "card") {
                    {
                      return paymentIcon.name !== selected ? (
                        <>
                          <SvgIcon
                            style={{ width: "80px", height: "80px", cursor: "pointer" }}
                            onClick={() => handleSelectPayment(paymentIcon.name)}
                          >
                            {paymentIcon.icon}
                          </SvgIcon>
                        </>
                      ) : (
                        <>
                          <SvgIcon
                            style={{
                              width: "80px",
                              height: "80px",
                              cursor: "pointer",
                              border: "2px solid",
                              borderColor: "#702B91",
                              borderRadius: "4px",
                              paddingInline: "4px",
                            }}
                            onClick={() => handleSelectPayment(paymentIcon.name)}
                          >
                            {paymentIcon.icon}
                          </SvgIcon>
                        </>
                      );
                    }
                  }
                  return null;
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.paymentOptionsSelect}>
              <span>{subTitles[1]}</span>
              <Box className={classes.paymentOptionsSelect_paymentTitle}>
                {paymentOptions.map((option: any) => {
                  const paymentIcon = optionsData.find((optionData) => optionData.name === option);
                  if (paymentIcon && paymentIcon.type === "other") {
                    {
                      return paymentIcon.name !== selected ? (
                        <>
                          <SvgIcon
                            style={{ width: "80px", height: "80px", cursor: "pointer" }}
                            onClick={() => handleSelectPayment(paymentIcon.name)}
                          >
                            {paymentIcon.icon}
                          </SvgIcon>
                        </>
                      ) : (
                        <>
                          <SvgIcon
                            style={{
                              width: "80px",
                              height: "80px",
                              cursor: "pointer",
                              border: "2px solid",
                              borderColor: "#702B91",
                              borderRadius: "4px",
                              paddingInline: "4px",
                            }}
                            onClick={() => handleSelectPayment(paymentIcon.name)}
                          >
                            {paymentIcon.icon}
                          </SvgIcon>
                        </>
                      );
                    }
                  }
                  return null;
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export { PaymentOptionsSelect };
